import request from '@/utils/axios'
// 公用API
// 请求示例
export function gethome (data) {
  return request({
    url: '/api/home',
    method: 'post',
    data
  })
}

// 提供服务
export function getService (data) {
  return request({
    url: '/api/home/index',
    method: 'post',
    data
  })
}

// 海师介绍
export function getIntroduce (data) {
  return request({
    url: '/api/home/index-zz',
    method: 'post',
    data
  })
}

// 成功案例
export function getCase (data) {
  return request({
    url: '/api/home/index-case',
    method: 'get',
    data
  })
}

// 身份画像
export function getIdentity (data) {
  return request({
    url: '/api/home/index-hx',
    method: 'post',
    data
  })
}

// 名校
export function getFamous (data) {
  return request({
    url: '/api/home/index-school',
    method: 'post',
    data
  })
}

// 专业概览
export function getMajor (data) {
  return request({
    url: '/api/home/index-major',
    method: 'post',
    data
  })
}

// 教授概览
export function getTeach (data) {
  return request({
    url: '/api/home/index-teach',
    method: 'post',
    data
  })
}

// 评价列表
export function getComment (data) {
  return request({
    url: '/api/home/index-eva',
    method: 'post',
    data
  })
}
